<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<v-col>
						<s-select-definition
							label="Linea Empaque"
							:def="1281"
							v-model="linePacking"
						></s-select-definition>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" lg="12">
						<s-text 
							label="Código" 
							@keyupEnter="keyupEnter" 
							@input="input($event)" 
							v-model="CbpCode"
							ref="stext"
						></s-text>
					</v-col>
				</v-row>
				
				
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sPrfCountingBoxesPresentationService from "../../../services/FreshProduction/PrfCountingBoxesPresentation"
	import _service from "@/services/FreshProduction/PrfAssignTurnWorKerDetailService";

	export default {
		data() {
			return {
				CbpCode: "",
				separator: "",
				aux: "",
				linePacking : 0
			}
		},

		watch: {
			CbpCode(newValue, oldValue) {
				console.log('cambiooo ', newValue, oldValue)
			}
		},

		methods: {
			Initialize()
			{
				let USA = this.$fun.hasSpecialPermission('USA');
				let EUR = this.$fun.hasSpecialPermission('EUR');
				let KOR = this.$fun.hasSpecialPermission('KOR');
				
				if (USA)
				{
					this.linePacking = 3;
				}
				if (EUR)
				{
					this.linePacking = 2;
				}
				if (KOR)
				{
					this.linePacking = 9;
				}
			},

			input(value)
			{
				
				//console.log('dato leido ', value);
				/* if(value.length > 16)
				{
					console.log('dato enviado');
				} */
				//let da = value.split(this.separator, 1)[0];
				//this.aux = da.slice(1, da.length)
				//this.CbpCode = aux;
				//console.log(this.CbpCode);
				//console.log(this.aux);
			},

			keyupEnter() {
				this.CbpCode = this.CbpCode + ','+this.linePacking;
				if(this.CbpCode != ""){

					_sPrfCountingBoxesPresentationService.save(this.CbpCode, this.$fun.getUserID())
					.then((resp) => {
						if(resp.status == 200){
							//this.CbpCode = ''
							this.$refs.stext.focus();
						}
					});
					this.CbpCode = ''
					console.log('click enter ', this.CbpCode);
				}
			}
		},

		created () {
			_service.codigoQR(this.$fun.getUserID())
			.then(resp => {
				if(resp.status == 200){
					if(resp.data)
					{
						this.separator = resp.data[0].PrmValue;
					}
				}
			});

			this.Initialize();
		},

		mounted () {
			this.$refs.stext.focus();
		},
		
	}
</script>