import Service from '../Service';

const resource = "countingbocespresentation/";

export default {

    save(CbpCode, requestID) {
        return Service.post(resource + "save", {}, {
            params: { CbpCode, requestID }
        });
    },


    packingboxes(obj, requestID) {
        return Service.post(resource + "packingboxes", obj, {
            params: { requestID }
        });
    }

}