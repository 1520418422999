import Service from "../Service";
import Vue from "vue";
const resource = "detailTurn/"

export default {
    save(prflin, requestID) {
        return Service.post(resource + "save", prflin, {
            params: { requestID: requestID },
        });
    },

    saveCountLabel(obj, requestID) {
        return Service.post(resource + "saveCountLabel", obj, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    codigoQR(RequestID) {
        return Service.post(resource + "codigoQR", {}, {
            params: { RequestID: RequestID }
        });
    },

};